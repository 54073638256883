@import "./variables.scss";
@import "./mixins.scss";

//scrollbar
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $background-color;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: $background-color;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primary-color;
}

*::-moz-selection {
    background: $primary-color;
    color: $background-color;
    text-shadow: none;
}

::-moz-selection {
    background: $primary-color;
    color: $background-color;
    text-shadow: none;
}

::selection {
    background: $primary-color;
    color: $background-color;
    text-shadow: none;
}

@font-face {
    font-family: "Reaver";
    font-weight: normal;
    src: url(../fonts/Reaver-Bold.woff);
}
@font-face {
    font-family: "Barlow";
    font-weight: normal;
    src: url(../fonts/barlow-bold.woff2);
}

@font-face {
    font-family: "Poppins";
    font-weight: normal;
    src: url(../fonts/Open-Sans.ttf);
}
@font-face {
    font-family: "Bona Nova";
    font-style: italic;
    src: url(../fonts/BonaNova-Italic.ttf);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    box-sizing: border-box;
    color: $secondary-color;
    font-family: "Space Mono";
    background-color: $background-color;
    scroll-behavior: smooth;
}

.main {
    width: 100%;
}

.button,
.hero-link {
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: $border-radius;
    padding: 0.75rem 1rem;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 0.9rem;
}

.section-container {
    height: 100vh;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;

    @include sm {
        padding: 30px 0 60px;
        min-height: 100vh;
        height: auto;
    }

    .section-heading {
        margin-bottom: 10px;
        @include text-guru("Reaver", 2.5rem, $light-primary-color);
        display: flex;
        align-items: center;
        position: relative;
        margin: 10px 0px 30px;
        width: 100%;
        white-space: nowrap;

        &::after {
            content: "";
            display: block;
            position: relative;
            top: 5px;
            width: 300px;
            height: 2px;
            margin-left: 30px;
            background: $primary-color;

            @include sm {
                width: 100px;
            }
        }
    }

    .section-text {
        @include text-guru("Poppins", 1rem, $secondary-color, normal, 1.7rem);
        margin-top: 20px;
        text-align: left;
        word-spacing: 3px;
    }
}

.show {
    display: block;
}

.hide {
    display: none;
}

//start header section
nav {
    justify-content: space-between;
    padding: 0 60px;
    height: 85px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: $dark-green-color;
    @include box-shadow;
    z-index: 2;

    @include md {
        padding: 0 30px;
    }

    @include sm {
        padding: 0 10px;
    }
}

.nav-menu {
    justify-content: space-between;
    width: 100%;

    .logo-holder {
        display: flex;
        align-items: center;

        .logo-text {
            color: $primary-color;
            font-family: "Barlow";
            font-size: 35px;
            color: #f84565;
            letter-spacing: 2px;
            margin-left: 20px;
        }
    }
}

.nav-menu,
nav {
    display: flex;
    align-items: center;
}

.nav-menulist {
    list-style-type: none;
    display: flex;
    height: 60px;
    align-items: flex-end;
    font-size: 0.9rem;

    @include sm {
        display: none;
    }
}

.nav-menulist .nav-items {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 1.1rem;
    margin-right: 1rem;
    @include text-guru("Barlow", 1.1rem, $white-color, normal, normal, normal);

    .first-letter {
        @include transition;
    }

    &:hover .first-letter {
        color: $primary-color;
    }

    .nav-icon {
        height: 40px;
        width: 40px;
    }

    .nav-resume {
        margin-left: 15px;
    }
}

//end header section

// hero section starts
.hero-section {
    height: calc(100vh - 85px);
    margin: 85px auto 0;
    background-image: url("../images/hero-pic.webp");
    background-attachment: fixed;
    background-size: cover;

    @include sm {
        margin-top: 80px;
    }

    .hero-wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: $device-width;
        padding-left: 70px;
        margin: 0 auto;

        @include lg {
            max-width: 900px;
        }

        @include md {
            max-width: 750px;
        }

        @include sm {
            width: 100%;
            padding: 0 30px;
        }
    }

    h1 {
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 2px;
        color: #fbfbfb;
        font-family: "Reaver";
        margin-bottom: 25px;
        text-shadow: 0 0 10px #000;

        @include sm {
            font-size: 3rem;
        }
    }

    h2 {
        font-size: 1.2rem;
        line-height: 3rem;
        letter-spacing: 2px;
        color: #c8c8c8;
        margin-bottom: 10px;
        font-family: "Bona Nova";
        font-style: italic;
        text-shadow: 0 0 10px #000;
    }

    .hero-link {
        position: relative;
        display: inline-block;
        padding: 13px 35px 13px 15px;
        font-size: 1rem;
        font-family: "Reaver";
        border: none;
        background-color: $light-primary-color;
        color: $dark-green-color;
        text-transform: uppercase;
        color: white;
        @include transition;
        animation: pulse-red 2s infinite;

        &:hover {
            color: $white-color;
            border-color: $white-color;
            background-color: $primary-color;
        }

        &::after {
            content: "";
            background: url("../images/right-icon.svg");
            background-size: 100% 100%;
            height: 25px;
            width: 25px;
            right: 5px;
            top: 10px;
            position: absolute;
        }
    }

    .contact-us-link {
        background-color: $off-white-color;
        padding: 13px 15px;
        color: $primary-color;
        margin-right: 20px;
        animation: none;

        &::after {
            display: none;
        }
    }
}

// hero section ends
//about section starts
#about {
    min-height: 100vh;
    height: auto;
    background: $off-white-color;
    background-size: 100% 100%;
}

.about-section {
    .about-wrapper {
        max-width: $device-width;
        margin: 0 auto;

        .section-heading {
            color: $dark-green-text-color;
            margin-bottom: 50px;

            &::after {
                background-color: $dark-green-color;
            }
        }

        @include lg {
            max-width: 900px;
        }

        @include md {
            max-width: 750px;
        }

        @include sm {
            padding: 0 30px;
        }

        .content-wrapper {
            display: flex;
            gap: 100px;
            padding-left: 30px;
            align-items: center;

            @include sm {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 0;
            }

            .section-text {
                color: $dark-green-color;
            }

            .text-wrapper {
                margin-right: 100px;

                @include md {
                    margin-right: 40px;
                }

                @include sm {
                    margin-right: 0px;
                }
            }
        }

        .img-wrapper {
            position: relative;

            @include sm {
                margin-top: 30px;
            }

            &::after {
                content: '';
                width: 300px;
                height: 300px;
                position: absolute;
                background: transparent;
                @include border-radius;
                border: 3px solid $dark-green-text-color;
                top: 10px;
                left: 10px;

                @include md {
                    width: 250px;
                    height: 250px;
                }
            }
        }

        .about-pic {
            width: 300px;
            height: 300px;
            padding: 0px;
            display: block;
            @include border-radius;
            background: $dark-green-color;
            position: relative;
            z-index: 1;

            @include md {
                width: 250px;
                height: 250px;
            }
        }
    }
}

//about section ends
//room section starts
#rooms {
    background: linear-gradient(rgb(14, 14, 14) 0%, $dark-green-color 100%);
    min-height: 100vh;
    height: auto;
    .section-heading {
        margin-bottom: 50px;
        @include sm {
            margin-top: 50px;
        }
    }

    .rooms-wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: $device-width;
        margin: 0 auto;

        .rooms-card-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            row-gap: 40px;
            flex-wrap: wrap;

            .rooms-card {
                max-width: 400px;
                border-radius: 10px;
                overflow: hidden;

                .rooms-content-holder {
                    background-color: $off-white-color;
                    padding: 20px;
                }

                .rooms-heading {
                    @include text-guru("Reaver", 1.2rem, $light-primary-color);
                }

                .rooms-text {
                    margin: 10px 0;
                    @include text-guru("Poppins", 1rem, $dark-green-color, normal, 1rem);
                }

                .price-wrapper {
                    display: flex;
                    margin-top: 25px;
                    align-items: center;

                    .price {
                        @include text-guru("Reaver", 1rem, $dark-green-color, normal, 1rem);
                        display: inline-block;
                    }

                    .rate {
                        display: inline-block;
                        @include text-guru("Reaver", 1rem, $primary-color, normal, 1rem);
                        margin-left: 15px;
                        border: 1px solid $primary-color;
                        padding: 8px;
                    }
                }
            }

            .tns-controls {
                transform: translateY(-150px);
                display: none;
                align-items: center;
                justify-content: space-between;

                button:first-child {
                    color: transparent;
                }
            }
        }

        @include lg {
            max-width: 900px;
        }

        @include md {
            max-width: 750px;
        }

        @include sm {
            width: 100%;
            padding: 0 30px;
        }
    }

}

//room section ends
// claendar section
#location {
    height: auto;
    min-height: 100vh;
}

.location-section {
    .location-container {
        max-width: $device-width;
        margin: 0 auto;

        .section-heading {
            margin: 80px 0 50px;
        }

        .location-flex {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .class{

                @include sm {
                    width: 100%;
                }
            }
            iframe {

                @include sm {
                    width: 100%;
                }
            }

            .location-helper-container {
                display: flex;
                justify-content: center;
                align-self: center;
                flex-wrap: wrap;
                margin-top: 50px;
                .location-card {
                    display: flex;
                    margin: 40px 0;
                    @include lg {
                        flex-direction: column;
                        align-items: center;
                    }
                    &:last-child {
                        flex-direction: row-reverse;
                        margin-bottom: 80px;
                        @include lg {
                            flex-direction: column;
                            align-items: center;
                        }
                        .text-container {
                        border: 1px solid $light-primary-color;
                        border-radius: none;
                        }
                    }
                    .text-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 30px;
                        border: 1px solid $light-primary-color;
                        border-left: none;
                        @include lg {
                            flex-direction: column;
                            border: 1px solid $light-primary-color;
                            border-top: none;
                            max-width: 500px;
                        }
                        @include sm {
                            width: 100%;
                        }
                        h4 {
                            @include text-guru("Reaver", 1.2rem, $light-primary-color);
                            margin-bottom: 30px;
                        }

                        p {
                            @include text-guru("Poppins", 1rem, $off-white-color, normal, 1.3rem);
                        }
                    }

                    img {
                        width: 500px;
                        height: 400px;
                        @include sm {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @include lg {
            max-width: 900px;
        }

        @include md {
            max-width: 750px;
        }

        @include sm {
            width: 100%;
            max-width: none;
            padding: 0 30px;
        }
    }
}


// claendar section ends
// socials section starts
.contact-section {
    background: $off-white-color;
    text-align: center;

    p {
        margin: 80px 0 35px;
        @include text-guru("Poppins", 2rem, $dark-green-color);
    }

    .heading {
        @include text-guru("Reaver", 1.5rem, $light-primary-color);
        text-decoration: underline;
        cursor: pointer;
        margin-left: 20px;
    }

    .text {
        margin: 10px 0;
        @include text-guru("Poppins", 1.5rem, $dark-green-color, normal, 1rem);
    }
    .flex-container {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:last-child {
            margin-left: 30px;
        }
    }
    .contact-container {
        max-width: $device-width;
        width: 100%;
        margin: 0 auto;

        @include lg {
            max-width: 900px;
        }

        @include md {
            max-width: 750px;
        }

        @include sm {
            width: 100%;
            padding: 0 30px;
        }
    }
}

// floater starts
.floater {
    position: fixed;
    bottom: 0;
    left: 75px;
    opacity: 0;
    @include transition;

    &::after {
        content: "";
        display: block;
        margin: auto;
        width: 2px;
        height: 100px;
        background-color: $white-color;
        border: 1px solid black;
    }

    @include lg {
        left: 20px;
    }

    @include lsm {
        display: none;
    }

    .email {
        margin-bottom: 30px;
        writing-mode: vertical-rl;
        letter-spacing: 2px;
        color: $white-color;
        background-color: black;
    }

    a {
        display: inline-block;
        padding: 13px;

        &:hover .floater-icon {
            fill: $primary-color;
            transform: translateY(-5px);
        }
    }

    .floater-icon {
        width: 22px;
        height: 22px;
        fill: $white-color;
        @include transition;
    }
}

// floater ends
// socials section ends
// animation starts
@keyframes svgDraw {
    0% {
        stroke-dashoffset: 2425;
        stroke: $primary-color;
    }

    100% {
        stroke-dashoffset: 0;
        stroke: $primary-color;
    }
}

// animation ends
// transition starts
.fade {
    opacity: 0;
    @include transition;
}

.ini-fu {
    transform: translateY(-20px);
}

.ini-fr {
    transform: translateX(-70px);
}

.fade-up {
    transform: translateY(0);
    opacity: 1;
}

.fade-left {
    transform: translateX(0);
    opacity: 1;
}

// transition ends
// mobile menu starts
#toggle {
    border: none;
    right: 30px;
    position: relative;
    cursor: pointer;
    width: 26px;
    background: transparent;
    height: 23px;

    @include gsm {
        display: none;
    }

    .ham-ico {
        right: 0px;
        width: 26px;
        height: 2px;
        background: $primary-color;
        position: relative;
        @include transition;

        &::before {
            content: "";
            width: 26px;
            height: 2px;
            top: -7px;
            left: 0;
            background: $primary-color;
            position: absolute;
            @include transition;
        }

        &::after {
            content: "";
            width: 26px;
            height: 2px;
            top: 9px;
            left: 0;
            background: $primary-color;
            position: absolute;
        }
    }

    &:hover .ham-ico::before {
        width: 6px;
    }

    &:hover .ham-ico {
        width: 16px;
    }
}

.mobile-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;

    &.show {
        pointer-events: all;

        .overlay {
            display: block;
        }

        #mobile-menu {
            transform: translateX(-100%);

            .nav-items {
                padding: 20px 12px;
            }
        }
    }
}

.overlay {
    display: none;
    opacity: 0.8;
    background: $background-color;
    width: 100%;
    height: 100%;
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

#mobile-menu {
    top: 0;
    display: block;
    position: fixed;
    right: -55%;
    width: 55%;
    background: $background-color;
    height: 100vh;

    .toggle {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 26px;
        height: 23px;
        border: none;
        background: transparent;
        cursor: pointer;

        .close {
            width: 26px;
            height: 2px;
            background-color: $primary-color;
            transform: rotate(45deg);
            pointer-events: none;

            &::after {
                content: "";
                width: 26px;
                height: 2px;
                display: block;
                background-color: $primary-color;
                transform: rotate(90deg);
            }
        }
    }

    ol {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }
}

// mobile menu ends

//animation
@keyframes pulse-red {
    0% {
        // transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        // transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        // transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}