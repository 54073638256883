//colors
$primary-color : #d31437;
$dark-green-color : #030f01;
$dark-green-text-color: #0b4a02;
$light-primary-color: lighten($primary-color,15%);
$white-color : #fbfbfb;
$off-white-color :darken($white-color,10%);
$secondary-color : #a5b2e3;
$background-color : #0e161c;
$dark-background-color: darken($background-color,2%);
//variables
$border-radius:5px;
$device-width: 1200px;