@mixin text-guru($family, $font-size, $color, $weight: normal, $line-height: normal, $letter-spacing: normal) {
    font-size: $font-size;
    line-height: $line-height;
    font-family: $family;
    color: $color;
    letter-spacing: $letter-spacing;
    font-weight: $weight;
}

@mixin transition {
    transition: all 0.4s ease-in;

}

@mixin box-shadow {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px 12px 50px -10px;
}

@mixin fade-in {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}

@mixin border-radius {
    border-radius: 10px;
}

@mixin lg {
    @media (max-width: 1300px) {
        @content;
    }
}
@mixin md {
    @media (max-width: 1100px) {
        @content;
    }
}

@mixin lsm {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin sm {
    @media (max-width: 800px) {
        @content;
    }
}


@mixin gsm {
    @media (min-width: 801px) {
        @content;
    }
}